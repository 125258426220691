import React from 'react';

import styled from '@emotion/styled';
import AutocompleteInput, {
  IAutocompleteInputProps,
  IOptionsDisplayProps,
  ResultList,
} from '@opendoor/bricks/complex/AutocompleteInput/AutocompleteInput';
import { IAutocompleteOption } from '@opendoor/bricks/complex/AutocompleteInput/AutocompleteOption';
import { CTAProps, IWrapperProps } from '@opendoor/bricks/complex/CtaInput/shared';
import { Box, Button, ButtonProps } from '@opendoor/bricks/core';
import { colors, space } from '@opendoor/bricks/theme/eero';
import { globalObservability } from '@opendoor/observability/slim';
import debounce from 'lodash/debounce';
import { WithRouterProps } from 'next/dist/client/with-router';
import { withRouter } from 'next/router';

import { OdProtosSellReceptionData_SellerInput_Channel } from '__generated__/athena';

// Generate unique session token for API billing purposes
// https://developers.google.com/maps/documentation/places/web-service/session-tokens
const generateGoogleMapsSessionToken = () => {
  return Math.floor(Math.random() * Number.MAX_SAFE_INTEGER).toString();
};

const ADDRESS_INPUT_URL = '/_address-input';

export interface IAddressInputAddress {
  street1: string;
  city: string;
  state: string;
  postal_code: string;
  unit?: string;
  latitude?: number;
  longitude?: number;
}

export interface IPostAddressOptions {
  manual?: boolean;
  agent_request?: boolean;
  seller_flow_uuid_override?: string;
  channel?: string;
  trackingKeywords?: string;
}

interface AddressInputDivProps {
  showPoweredByGoogle: boolean;
}

const AddressInputDiv = styled(Box)<AddressInputDivProps>`
  display: block;
  position: relative;

  & .invalid-input,
  & .autocomplete--error {
    border: none;
    box-shadow: none;
  }

  ${(props) =>
    props.showPoweredByGoogle &&
    `
      & ${ResultList}::after {
        content: 'powered by Google';
        display: block;
        width: 98%;
        color: transparent;
        background: url('https://images.opendoor.com/source/s3/imgdrop-production/powered-by-google.png?preset=square-2048&h=18')
          no-repeat center;
        background-size: 104px 16px;
        font-size: 14px;
      }
    `}
`;

const InlineError = styled(ResultList)`
  background-color: ${colors.red50};
  color: #fff;
  padding: ${space[2]}px ${space[6]}px;
  border: none;
  text-align: center;
  margin-top: -1px;
`;

const InlineErrorLink: React.FC<ButtonProps> = (props) => (
  <Button
    variant="inline-link"
    color="neutrals0"
    _hover={{ color: 'neutrals30' }}
    _active={{ color: 'neutrals30' }}
    backgroundColor="transparent"
    {...props}
  />
);

const ALLOWED_TYPES = ['street_address', 'premise', 'subpremise', 'route'];

type BaseAddressInputProps = IWrapperProps &
  Pick<
    IAutocompleteInputProps,
    'onFocus' | 'onBlur' | 'autoFocus' | 'className' | 'ariaLabelledby'
  > & {
    /**
     * Name of the address input in analytics events, should be unique within the app
     * Convention: {appName}-{addressInputName}
     */
    analyticsName: string;
    autocompleteService?: google.maps.places.AutocompleteService;
    channel?: OdProtosSellReceptionData_SellerInput_Channel;
    hideErrorFormLink?: boolean;
    initialInput?: string;
    onChooseManualEntry?: (
      channel?: OdProtosSellReceptionData_SellerInput_Channel,
      address?: IAddressInputAddress,
    ) => void;
    onQueryChange?: (newQuery: string, oldQuery: string) => void;
    onValidate?: (invalidUserInput: boolean) => void;
    onSubmit?: (address: IAddressInputAddress, channel?: string) => void;
    // called when the component is ready, useful for analytics
    onInputReady?: () => void;
    optionsDisplayProps?: IOptionsDisplayProps;
    paddingLevel?: 'none' | 'light' | 'medium';
    placeholderText?: string;
    'aria-label'?: string;
    placesService?: google.maps.places.PlacesService;
    renderError?: () => void;
    setValueOnBlur?: boolean;
    showError?: boolean;
    shouldRedirectOnSubmit?: boolean;
    showCta?: boolean;
  };

type AddresInputProps = BaseAddressInputProps & { showCta: false };
type AddressInputWithCtaProps = BaseAddressInputProps & {
  showCta: true;
  analyticsName?: string;
  ctaProps: CTAProps;
};

export type IProps = AddresInputProps | AddressInputWithCtaProps;
type IPropsWithRouter = IProps & WithRouterProps;

type AutocompletePrediction = google.maps.places.AutocompletePrediction & { source: string };

interface CasaAutocompletePrediction {
  description: string;
  addressToken: string;
  source: string;
}

type AddressOption = IAutocompleteOption & {
  prediction: AutocompletePrediction | CasaAutocompletePrediction;
};

type AddressInputState = {
  addressError: boolean;
  hasLoaded: boolean;
  autocompleteOptions: Array<AutocompletePrediction | CasaAutocompletePrediction>;
  fetchIsLoading: boolean;
  addressLoading: boolean;
  submitOnLoadingComplete: boolean;
  query: string;
  selectedIndex: number;
  sessionToken: string;
  isPoweredByGoogle: boolean;
};

/* storybook-check-ignore */
class AddressInput extends React.Component<IPropsWithRouter> {
  public static defaultProps: Partial<IPropsWithRouter> = {
    setValueOnBlur: false,
    hideErrorFormLink: false,
    onChooseManualEntry: () => {},
    onQueryChange: () => {},
    onSubmit: () => {},
    onInputReady: () => {},
    onFocus: () => {},
    onBlur: () => {},
    paddingLevel: 'medium',
    placeholderText: 'Enter your home address',
    showBorder: true,
    showShadow: false,
    showCta: true,
    ctaProps: {
      actionText: 'Get offer',
      variant: 'primary',
      'aria-label': '',
    },
    unboundedWidth: true,
    showError: true,
    shouldRedirectOnSubmit: true,
  };

  public state: AddressInputState = {
    addressError: false,
    hasLoaded: false,
    autocompleteOptions: [] as Array<AutocompletePrediction>,
    fetchIsLoading: false,
    addressLoading: false,
    submitOnLoadingComplete: false,
    query: this.props.initialInput || '',
    selectedIndex: -1,
    sessionToken: generateGoogleMapsSessionToken(),
    isPoweredByGoogle: false,
  };

  private isCasaPrediction(
    prediction: AutocompletePrediction | CasaAutocompletePrediction,
  ): prediction is CasaAutocompletePrediction {
    return (prediction as CasaAutocompletePrediction)?.addressToken !== undefined;
  }

  private fetchAutocompleteOptions = debounce((input: string) => {
    this.setState({ addressError: false, fetchIsLoading: true });
    if (!input) {
      this.setState({
        addressError: false,
        autocompleteOptions: [],
        selectedIndex: -1,
      });
      return;
    }

    this.setState({ addressError: false });
    fetch(this.buildAutocompleteUrl(input))
      .then((res) => res.json())
      .then((res) => {
        res.predictions?.forEach((result: AutocompletePrediction | CasaAutocompletePrediction) => {
          result.description = result.description.replace(', USA', '');
        });

        let autocompleteOptions: Array<AutocompletePrediction | CasaAutocompletePrediction> = [];
        if (res.source === 'casa') {
          autocompleteOptions = res.predictions;
          this.setState({ isPoweredByGoogle: false });
        } else {
          autocompleteOptions = res.predictions.filter((result: AutocompletePrediction) => {
            const resultAllowedTypes = result.types.filter((value) =>
              ALLOWED_TYPES.includes(value),
            );
            return (
              (result.terms.length >= 5 && resultAllowedTypes.length != 0) ||
              // Ensures the string starts with a house number (digits with optional suffix), followed by a space, then the beginning of a street name and another space
              (result.terms.length === 4 && result.terms[0].value.match(/^\d+\S*\s\S+\s/) !== null)
            );
          });
          this.setState({ isPoweredByGoogle: true });
        }
        this.setState({ autocompleteOptions, fetchIsLoading: false, hasLoaded: true });
        // For direct mail qr code scan, the prefilled address from casa might not completely match with auto complete result
        // so we want to update the query to the first prediction result
        const correctedPrefilledAddress = autocompleteOptions[0]?.description;
        const prefilledAddress = this.props.router?.query?.pa;
        if (
          this.state.query === prefilledAddress &&
          correctedPrefilledAddress &&
          prefilledAddress &&
          correctedPrefilledAddress !== prefilledAddress
        ) {
          this.setState({ query: correctedPrefilledAddress }, () => {
            this.handleQueryChange(correctedPrefilledAddress, false);
          });
        }
      })
      .catch(() => this.setState({ addressError: true, fetchIsLoading: false }));
  }, 100);

  public handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (!this.state.query.trim()) {
      return;
    }
    if (!this.state.addressLoading) {
      this.chooseFirstOrSelectedItem();
    }
  };

  public handleBlur: IAutocompleteInputProps['onBlur'] = (
    e: React.FocusEvent<HTMLInputElement>,
  ) => {
    if (this.props.setValueOnBlur && this.state.selectedIndex < 0) {
      this.chooseFirstOrSelectedItem();
    }
    this.props.onBlur && this.props.onBlur(e);
  };

  public handleQueryChange = (newValue: string, fromResultsSelection: boolean) => {
    if (!fromResultsSelection) {
      this.props.onQueryChange && this.props.onQueryChange(newValue, this.state.query);
      // use handleQueryChange for fetching results instead of updateOptions since
      // updateOptions has a default debounce of 500, and we want to be quicker
      // in this case
      this.fetchAutocompleteOptions(newValue);
      this.setState({ query: newValue, hasLoaded: false });
    } else {
      // user has used the arrow keys or mouse to select a value in our results dropdown
      this.setState({ query: newValue });
    }
  };

  // Populate the initial autocomplete options. These aren't shown, but they are
  // required in order for the AutocompleteInput to work
  public componentDidMount() {
    if (this.state.query !== '') {
      this.fetchAutocompleteOptions(this.state.query);
    }
    const prefilledAddress = this.props.router?.query?.pa;
    if (prefilledAddress) {
      this.setState({ query: prefilledAddress, hasLoaded: false }, () => {
        this.handleQueryChange(prefilledAddress as string, false);
      });
    }
  }

  public componentDidUpdate(_prevProps: IPropsWithRouter, prevState: AddressInputState) {
    if (
      this.state.submitOnLoadingComplete &&
      this.state.fetchIsLoading == false &&
      prevState.fetchIsLoading == true
    ) {
      this.chooseFirstOrSelectedItem();
      this.setState({ submitOnLoadingComplete: false });
    }
  }

  public render() {
    const {
      ariaLabelledby,
      autoFocus,
      className,
      paddingLevel,
      placeholderText,
      optionsDisplayProps,
      unboundedWidth,
      designStyle,
      showCta,
      showBorder,
      showShadow,
      showError,
      size,
      analyticsName,
      'aria-label': ariaLabel,
    } = this.props;

    const {
      query,
      addressLoading,
      autocompleteOptions,
      fetchIsLoading,
      addressError,
      isPoweredByGoogle,
    } = this.state;

    return (
      <AddressInputDiv
        showPoweredByGoogle={isPoweredByGoogle}
        className={'od-address-input ' + (className || '')}
      >
        <AutocompleteInput
          autoComplete="off"
          ariaLabelledby={ariaLabelledby}
          ariaLabel={ariaLabel}
          analyticsName={analyticsName}
          name="AddressInput"
          input={query || ''}
          showCta={showCta}
          {...(showCta && 'ctaProps' in this.props
            ? {
                ctaProps: {
                  ...this.props.ctaProps,
                  actionText: this.props.ctaProps?.actionText ?? 'Get offer',
                  variant: this.props.ctaProps?.variant ?? 'primary',
                  onClick: this.handleClick,
                  loading: addressLoading,
                  disabled: addressLoading,
                  size: size,
                },
              }
            : {})}
          inputProps={{
            id: 'address-input',
            autoFocus,
            paddingLevel,
            unboundedWidth,
            designStyle,
            showBorder,
            showShadow,
          }}
          optionsDisplayProps={optionsDisplayProps}
          placeholderText={placeholderText}
          onInputChange={this.handleQueryChange}
          select={this.handleItemClick as any}
          onFocus={this.onInputFocus}
          onBlur={this.handleBlur}
          options={autocompleteOptions.map(this.placeToOption)}
          failure={addressError}
          renderError={showError ? this.displayManualEntry : undefined}
          fetchOptionsStatus={{ isLoading: fetchIsLoading }}
        />
      </AddressInputDiv>
    );
  }

  public onInputFocus = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    this.props.onFocus && this.props.onFocus(e);
  };

  public handleItemClick = (autocompleteItem: AddressOption) => {
    if (!autocompleteItem.value) {
      // if autocompleteItem does not a value, then it is the raw
      // text typed in from the user
      this.chooseFirstOrSelectedItem();
    } else {
      this.chooseResult({ result: autocompleteItem });
    }
  };

  private buildAutocompleteUrl = (query: string) => {
    return `${ADDRESS_INPUT_URL}/autocomplete?sessionToken=${this.state.sessionToken}&query=${query}`;
  };

  private buildPlacesUrl = (placeId: string, addressToken: string, source: string) => {
    const baseUrl = `${ADDRESS_INPUT_URL}/place`;
    if (source === 'casa') {
      return baseUrl + `?addressToken=${addressToken}`;
    }
    return baseUrl + `?sessionToken=${this.state.sessionToken}&placeId=${placeId}`;
  };

  private fetchAddress = (
    prediction: AddressOption,
    onSuccess: (address: IAddressInputAddress) => void,
  ): void => {
    let buildPlacesUrl = '';
    if (this.isCasaPrediction(prediction.prediction)) {
      buildPlacesUrl = this.buildPlacesUrl('', prediction.prediction.addressToken, 'casa');
    } else {
      buildPlacesUrl = this.buildPlacesUrl(prediction.prediction.place_id, '', 'google');
    }
    fetch(buildPlacesUrl)
      .then((res) => res.json())
      .then((address: IAddressInputAddress) => onSuccess(address))
      .catch((error) => {
        globalObservability.getSentryClient().captureException?.(error);
        this.setError();
        this.props.onValidate && this.props.onValidate(false);
      });
  };

  private chooseResult = ({
    result,
    firstPrediction,
  }: {
    result?: AddressOption;
    firstPrediction?: AddressOption;
  }) => {
    if (!result || !result.prediction) {
      if (this.state.fetchIsLoading || !this.state.hasLoaded) {
        // If we've not loaded any results since last query change and we are
        // loading still, then wait until loading has completed before deciding
        // where to send the user
        if (!this.state.submitOnLoadingComplete) {
          this.setState({ submitOnLoadingComplete: true, addressLoading: true });
        }
        return;
      }
      this.setState({ addressLoading: true });

      if (firstPrediction) {
        this.fetchAddress(firstPrediction, (address: IAddressInputAddress) => {
          return (
            this.props.onChooseManualEntry &&
            this.props.onChooseManualEntry(this.props.channel, address)
          );
        });
      }
      return this.props.onChooseManualEntry && this.props.onChooseManualEntry(this.props.channel);
    }

    this.setState({ addressLoading: true });

    this.fetchAddress(result, (address: IAddressInputAddress) => {
      this.submit(address);
      this.props.onValidate && this.props.onValidate(true);
      return;
    });
  };

  private setError() {
    this.setState({ addressLoading: false });
  }

  private submit = (place: IAddressInputAddress) => {
    this.props.onSubmit && this.props.onSubmit(place, this.props.channel);
    this.props.shouldRedirectOnSubmit || this.setState({ addressLoading: true });
  };

  private doesQueryMatch = (
    prediction: google.maps.places.AutocompletePrediction,
    query: string,
  ) => {
    let matchedAtStart = false;
    // see how much of our query string matches the parts google has marked as matching
    const matchedLength = prediction.matched_substrings.reduce((total, substr) => {
      if (substr.offset == 0) {
        matchedAtStart = true;
      }
      // make sure matched_substring actually occurs in query
      if (query.indexOf(prediction.description.substr(substr.offset, substr.length)) !== -1) {
        return total + substr.length;
      }
      return total;
    }, prediction.matched_substrings.length - 1);

    if (
      // if we did not match the query from the beginning, send to manual
      !matchedAtStart ||
      // if we somehow get here and query is empty, go to manual entr
      query.length == 0 ||
      // if the match is < 80% the same, then send user to manual entry
      matchedLength / query.length < 0.8
    ) {
      return false;
    }
    return true;
  };

  private doesQueryMatchCasa = (prediction: string, query: string): boolean => {
    if (query.length === 0) {
      return false;
    }

    const lowerAddress = prediction.toLowerCase();
    const lowerQuery = query.toLowerCase();

    if (!lowerAddress.startsWith(lowerQuery)) {
      return false;
    }

    let matchedLength = 0;
    for (let i = 0; i < Math.min(lowerAddress.length, lowerQuery.length); i++) {
      if (lowerAddress[i] !== lowerQuery[i]) {
        break;
      }
      matchedLength++;
    }

    return matchedLength / query.length >= 0.8;
  };

  private chooseFirstOrSelectedItem = () => {
    // selectedIndex is only set when the user uses arrow keys to select an item
    const { autocompleteOptions, selectedIndex } = this.state;
    const prediction = autocompleteOptions[selectedIndex] || autocompleteOptions[0];

    const buildPredictionResult = () => ({
      displayValue: prediction.description,
      value: prediction.description,
      prediction,
    });

    // Test to see if we have a good prediction match
    // This is to help avoid where users enter a search but we use the first
    // result even if it is a poor match.
    //
    // This can happen when a user enters an address that google/casa does not know
    // about.
    //
    // If we have 4 or 5 matched sub strings then it is probably a good enough match
    const isFirstPredictionFallback =
      selectedIndex === -1 &&
      prediction &&
      ((this.isCasaPrediction(prediction) &&
        this.state.query.length < 4 &&
        !this.doesQueryMatchCasa(prediction.description, this.state.query)) ||
        (!this.isCasaPrediction(prediction) &&
          prediction.matched_substrings.length < 4 &&
          !this.doesQueryMatch(prediction, this.state.query)));

    if (isFirstPredictionFallback) {
      return this.chooseResult({ firstPrediction: buildPredictionResult() });
    }

    // match looks decent enough or is undefined
    return this.chooseResult({ result: prediction ? buildPredictionResult() : undefined });
  };

  private goToManualEntry = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    this.props.onChooseManualEntry && this.props.onChooseManualEntry(this.props.channel);
  };

  private placeToOption = (
    prediction: AutocompletePrediction | CasaAutocompletePrediction,
  ): AddressOption => {
    return {
      displayValue: prediction.description,
      value: prediction.description,
      prediction,
    };
  };

  private displayManualEntry = () => {
    const { designStyle } = this.props;
    return (
      <small>
        <InlineError className="inline-error" designStyle={designStyle}>
          We couldn't find that address. Try without zip codes or unit numbers.
          {this.props.hideErrorFormLink || (
            <>
              {' '}
              Or try our{' '}
              <InlineErrorLink
                analyticsName={`${this.props.analyticsName}-inline-error`}
                aria-label="Navigate to this link to manually submit an address"
                onClick={this.goToManualEntry}
              >
                simplified form
              </InlineErrorLink>
              .
            </>
          )}
        </InlineError>
      </small>
    );
  };
}

export default withRouter(AddressInput);
