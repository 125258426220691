/* storybook-check-ignore */

/* storybook-check-ignore */
import * as React from 'react';

import { useAuth } from '@opendoor/auth-fe';
import { CTAProps } from '@opendoor/bricks/complex/CtaInput';
// TODO: Label should not be used directly and should stay as an internal utility for bricks form components. Please update the usage of Label.
// eslint-disable-next-line no-restricted-imports
import { Label, LabelProps } from '@opendoor/bricks/core';
import { IAddressInputAddress } from '@opendoor/cinderblocks/growth/AddressInput/AddressInput';
import { EventTrackingAction, globalObservability } from '@opendoor/observability/slim';

import {
  OdProtosSellReceptionData_SellerInput_AnalyticsMetadata_Product,
  OdProtosSellReceptionData_SellerInput_Channel,
} from '__generated__/athena';

import {
  ADDRESS_INVALID_RESULT,
  getAddressValidationExperimentVariant,
} from '../../helpers/AddressValidationContext';
import { useObservability } from '../../helpers/observability';
import { standardizeAddress, updateAnswers } from '../api';
// TODO(betty): Move new address input component into Cinderblocks after React 18 upgrade
// import { AddressInput, IAddressInputProps } from '@opendoor/cinderblocks/growth/AddressInput';
import { startAddressVerification } from '../api/addressInputHelpers';
import { CONSUMER_FE_URL, COSMOS_URL } from '../globals';
import AddressInput, { IProps as IAddressInputProps } from './NewAddressInput';
import { setCookie } from './trackers';

const ADDRESS_QUERY_PARAM = [
  'street',
  'city',
  'postalCode',
  'state',
  'result',
  'unit',
  'street1',
  'postal_code',
];
const UNIVERSAL_NIL_ADDRESS = '6aae647b-d603-5533-8464-c42c658b101a';
const Sentry = globalObservability.getSentryClient();

export type { IAddressInputAddress };

type IAddressInputPropsWithoutCallbacks = Omit<
  IAddressInputProps,
  'onSubmit' | 'onChooseManualEntry' | 'googleMapsApiKey' | 'analyticsName'
>;
export interface SellerFlowAddressInputProps extends IAddressInputPropsWithoutCallbacks {
  trackingKeywords?: string;
  partnerReferralId?: string;
  trackingTaxonomy?: string;
  inputLocation?: string;
  ctaProps: CTAProps;
  hideLabel?: boolean;
  pageSetPath?: string;
  // We only want to display above for sticky CTA mobile
  displayAbove?: boolean;
  placeholderText?: string;
  /**
   * Name of the address input in analytics events, should be unique within the app
   * Convention: {appName}-{addressInputName}
   */
  analyticsName: string;
  product: OdProtosSellReceptionData_SellerInput_AnalyticsMetadata_Product;
  /** Whether to show label in dark theme (white color) or not */
  labelProps?: LabelProps;
  /**
   * Override the onSuccess callback to do something other than redirect to reception-fe
   */
  onSuccess?: (address: IAddressInputAddress, token: string, pdpUrl?: string) => void;
  /**
   * Override the onChooseManualEntry callback to do something other than redirect to reception-fe
   */
  onChooseManualEntry?: IAddressInputProps['onChooseManualEntry'];
}

export const checkAndMaybeStartManualAddressVerification = async ({
  forceRedirect,
  address,
  customerUUID,
  channel,
  trackingKeywords,
}: {
  forceRedirect: boolean;
  address?: IAddressInputAddress;
  customerUUID?: string | null;
  channel?: OdProtosSellReceptionData_SellerInput_Channel;
  trackingKeywords?: string;
}) => {
  if (!address) {
    await getAddressValidationExperimentVariant(customerUUID);
    startManualAddressVerification(channel, trackingKeywords);
    return true;
  }

  if (forceRedirect) {
    await getAddressValidationExperimentVariant(customerUUID);
    startManualAddressVerification(channel, trackingKeywords, address);
    return true;
  }

  const standardizeAddressResponse = await standardizeAddress(address);
  if (
    !standardizeAddressResponse ||
    !standardizeAddressResponse.data ||
    standardizeAddressResponse.errors ||
    standardizeAddressResponse.data?.casa.findByFields?.address?.uuid === UNIVERSAL_NIL_ADDRESS
  ) {
    await getAddressValidationExperimentVariant(customerUUID);
    startManualAddressVerification(channel, trackingKeywords, address);
    return true;
  }

  const casaAddress = standardizeAddressResponse.data?.casa.findByFields?.address;
  if (ADDRESS_INVALID_RESULT.some((error) => casaAddress?.result.includes(error))) {
    const addressValidationExperimentVariant = await getAddressValidationExperimentVariant(
      customerUUID,
    );
    if (addressValidationExperimentVariant === 'treatment') {
      startManualAddressVerification(channel, trackingKeywords, casaAddress);
      return true;
    }
  }
  return false;
};

export const handleAddressVerificationSuccess = ({
  address,
  token,
  trackEvent,
  pdpUrl,
  pageSetPath,
  onSuccess,
  inNewAddressEntryExperiment,
}: {
  address: IAddressInputAddress;
  token: string;
  trackEvent: ReturnType<typeof useObservability>['trackEvent'];
  pdpUrl?: string;
  pageSetPath?: string;
  onSuccess?: SellerFlowAddressInputProps['onSuccess'];
  inNewAddressEntryExperiment?: boolean;
}) => {
  const performRedirection = () => {
    if (pdpUrl) {
      trackEvent('inputting_address_success' as EventTrackingAction, 'pdp-redirect');
      if (onSuccess) {
        return onSuccess(address, token, pdpUrl);
      }
      window.location.href = `${COSMOS_URL}${pdpUrl}?utm_source=seller&utm_medium=web&utm_campaign=ae_pdp`;
    } else {
      trackEvent('inputting_address_success' as EventTrackingAction, 'onboarding-redirect');
      if (onSuccess) {
        return onSuccess(address, token, pdpUrl);
      }
      const redirectUrl = pageSetPath
        ? `${CONSUMER_FE_URL}/seller-lead/${token}${pageSetPath}`
        : `${CONSUMER_FE_URL}/seller-lead/${token}`;
      window.location.href = redirectUrl;
    }
  };

  if (inNewAddressEntryExperiment) {
    updateAnswers({ 'home.user_verified_address': true }, '', token)
      .then(() => {
        performRedirection();
      })
      .catch((err) => {
        Sentry.captureException?.(err);
      });
  } else {
    performRedirection();
  }
};

export const handleAddressSubmit = async (
  address: IAddressInputAddress,
  trackEvent: ReturnType<typeof useObservability>['trackEvent'],
  product: OdProtosSellReceptionData_SellerInput_AnalyticsMetadata_Product,
  trackingTaxonomy?: string,
  channel?: OdProtosSellReceptionData_SellerInput_Channel,
  trackingKeywords?: string,
  inputLocation?: string,
  onSuccess?: SellerFlowAddressInputProps['onSuccess'],
  partnerReferralId?: string,
  pageSetPath?: string,
  customerUUID?: string | null,
) => {
  // Setting cookie to know where the customer is coming from in reception-fe for experiments
  if (trackingTaxonomy) {
    setCookie('sourceForTracking', trackingTaxonomy, 1);
  }

  const manualVerificationStarted = await checkAndMaybeStartManualAddressVerification({
    forceRedirect: false,
    address: address,
    customerUUID: customerUUID,
    channel: channel,
    trackingKeywords: trackingKeywords,
  });

  if (manualVerificationStarted) {
    return;
  }

  const error = await startAddressVerification({
    address,
    product,
    channel,
    trackingKeywords,
    inputLocation,
    onSuccess: (token, pdpUrl, pageSetPath) => {
      handleAddressVerificationSuccess({
        address,
        token,
        trackEvent,
        pdpUrl,
        pageSetPath,
        onSuccess,
      });
    },
    partnerReferralId,
    pageSetPath,
  });
  if (error) {
    if (error.type === 'invalid-address') {
      await checkAndMaybeStartManualAddressVerification({
        address: address,
        forceRedirect: true,
        customerUUID: customerUUID,
        channel: channel,
        trackingKeywords: trackingKeywords,
      });
    } else {
      window.location.href = '/error';
    }
  }
};

const startManualAddressVerification = (
  channel?: string,
  trackingKeywords?: string,
  address?: any,
) => {
  const path = `${CONSUMER_FE_URL}/seller-lead/address-manual`;
  const searchParams = new URLSearchParams();
  if (channel) {
    searchParams.set('channel', channel);
  }
  if (trackingKeywords) {
    searchParams.set('keywords', trackingKeywords);
  }

  if (address) {
    ADDRESS_QUERY_PARAM.forEach((param) => {
      if (address[param]) {
        let queryParamName = param;
        if (param === 'street1') {
          queryParamName = 'street';
        } else if (param === 'postal_code') {
          queryParamName = 'postalCode';
        }
        searchParams.set(queryParamName, address[param]);
      }
    });
  }

  const params = searchParams.toString();
  window.location.assign(path + (params ? `?${params}` : ''));
};

export const SellerFlowAddressInput: React.FC<SellerFlowAddressInputProps> = ({
  className,
  inputLocation,
  trackingKeywords,
  partnerReferralId,
  trackingTaxonomy,
  channel,
  hideLabel,
  displayAbove,
  placeholderText,
  product,
  labelProps,
  onChooseManualEntry,
  onSuccess,
  showError = false,
  pageSetPath,
  ...others
}) => {
  const { trackEvent } = useObservability();
  const { user } = useAuth();

  return (
    <>
      {!hideLabel && (
        <Label
          display="inline-block"
          mb="4"
          fontWeight="semibold"
          color="neutrals0"
          id="address-input-label"
          fontSize="s0"
          htmlFor="address-input"
          {...labelProps}
        >
          Enter your home address
        </Label>
      )}
      <AddressInput
        ariaLabelledby="address-input-label"
        aria-label="Enter your home address"
        showError={showError}
        className={className}
        placeholderText={placeholderText ?? '125 W Muriel Dr'}
        onSubmit={(address: IAddressInputAddress) => {
          handleAddressSubmit(
            address,
            trackEvent,
            product,
            trackingTaxonomy,
            channel,
            trackingKeywords,
            inputLocation,
            onSuccess,
            partnerReferralId,
            pageSetPath,
            user?.customerUuid,
          );
        }}
        onChooseManualEntry={
          onChooseManualEntry ||
          ((
            providedChannel?: OdProtosSellReceptionData_SellerInput_Channel,
            providedAddress?: IAddressInputAddress,
          ) => {
            checkAndMaybeStartManualAddressVerification({
              address: providedAddress,
              forceRedirect: true,
              customerUUID: user?.customerUuid,
              channel: providedChannel || channel,
              trackingKeywords: trackingKeywords,
            });
          })
        }
        onValidate={(isValid) => {
          if (isValid) {
            return;
          }
          // onValidate is called if we get a correct address back from google
          // but then the place_id verification fails, example is:
          // 1673 Sunburst St, Northridge, CA, USA
          onChooseManualEntry
            ? onChooseManualEntry(channel)
            : checkAndMaybeStartManualAddressVerification({
                forceRedirect: true,
                customerUUID: user?.customerUuid,
                channel: channel,
                trackingKeywords: trackingKeywords,
              });
        }}
        channel={channel}
        optionsDisplayProps={{ displayAbove: displayAbove }}
        {...others}
      />
    </>
  );
};
